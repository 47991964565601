import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as SharedStyle from '../../shared-style';
import FormNumberInput from './form-number-input';

const FT_TO_INCH = 12;

export default function XDFeetInchesInput({
  value, min, max, precision, onChange, children, style, immediate, hideConfirm,
  feetProps, inchesProps, feetStyle, inchesStyle,
}, ctx) {

  const feet = Math.floor(value);
  const inches = (value - feet) * FT_TO_INCH;

  function onChangeFeet(val) {
    val = isNaN(val) ? 0 : val;
    const different = parseFloat(feet).toFixed(precision) !== parseFloat(val).toFixed(precision);
    let newValue = val + inches / FT_TO_INCH;
    newValue = Math.max(min, Math.min(max, newValue));
    if (different) {
      onChange(newValue);
    }
  }

  function onChangeInches(val) {
    val = isNaN(val) ? 0 : val;
    const different = parseFloat(inches).toFixed(precision) !== parseFloat(val).toFixed(precision);
    let newValue = feet < 0 ? -val : val;
    const _ftOverflow = Math.floor(newValue / FT_TO_INCH);
    const _in = newValue % FT_TO_INCH;
    newValue = feet + _ftOverflow + _in / FT_TO_INCH;
    newValue = Math.max(min, Math.min(max, newValue));
    if (different) {
      onChange(newValue);
    }
  }

  return (
    <div style={{
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: '0.5em',
      ...style
    }}>
      <FormNumberInput
        value={feet}
        onChange={({ target: { value }}) => onChangeFeet(value)}
        precision={0}
        placeholder="Ft"
        immediate={immediate}
        hideConfirm={hideConfirm}
        {...feetProps}
        style={feetStyle}
      />
      <div>{"'"}</div>
      <FormNumberInput
        value={inches}
        onChange={({ target: { value }}) => onChangeInches(value)}
        min={0}
        max={11.999}
        precision={precision}
        placeholder="In"
        immediate={immediate}
        hideConfirm={hideConfirm}
        {...inchesProps}
        style={inchesStyle}
      />
      <div>{'"'}</div>
      {children}
    </div>
  );
}

XDFeetInchesInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.object,
  onChange: PropTypes.func,
  //onValid: PropTypes.func,
  //onInvalid: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  precision: PropTypes.number,
  immediate: PropTypes.bool, // if true, onChange is called with every valid value change
  hideConfirm: PropTypes.bool,
  feetProps: PropTypes.object,
  inchesProps: PropTypes.object,
  feetStyle: PropTypes.object,
  inchesStyle: PropTypes.object,
};

XDFeetInchesInput.defaultProps = {
  value: 0,
  style: {},
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
  precision: 3,
  onChange: (v) => { },
  //onValid: () => { },
  //onInvalid: () => { },
};
