import { XD_LINE_ITEM_REF_ACTIONS } from '../xd-constants';

export function addLineItemToLine(layerID, lineID, lineItemID, quantity) {
  return {
    type: XD_LINE_ITEM_REF_ACTIONS.ADD_LINE_ITEM_TO_LINE,
    layerID,
    lineID,
    lineItemID,
    quantity,
  }
}

export function editLineItemInLine(layerID, lineID, lineItemID, changes) {
  return {
    type: XD_LINE_ITEM_REF_ACTIONS.EDIT_LINE_ITEM_IN_LINE,
    layerID,
    lineID,
    lineItemID,
    changes,
  }
}

export function removeLineItemFromLine(layerID, lineID, lineItemID) {
  return {
    type: XD_LINE_ITEM_REF_ACTIONS.REMOVE_LINE_ITEM_FROM_LINE,
    layerID,
    lineID,
    lineItemID,
  }
}

export function addLineItemToArea(layerID, areaID, lineItemID, quantity) {
  return {
    type: XD_LINE_ITEM_REF_ACTIONS.ADD_LINE_ITEM_TO_AREA,
    layerID,
    areaID,
    lineItemID,
    quantity,
  }
}

export function editLineItemInArea(layerID, areaID, lineItemID, changes) {
  return {
    type: XD_LINE_ITEM_REF_ACTIONS.EDIT_LINE_ITEM_IN_AREA,
    layerID,
    areaID,
    lineItemID,
    changes,
  }
}

export function removeLineItemFromArea(layerID, areaID, lineItemID) {
  return {
    type: XD_LINE_ITEM_REF_ACTIONS.REMOVE_LINE_ITEM_FROM_AREA,
    layerID,
    areaID,
    lineItemID,
  }
}

export function addLineItemToHole(layerID, holeID, lineItemID, quantity) {
  return {
    type: XD_LINE_ITEM_REF_ACTIONS.ADD_LINE_ITEM_TO_HOLE,
    layerID,
    holeID,
    lineItemID,
    quantity,
  }
}

export function editLineItemInHole(layerID, holeID, lineItemID, changes) {
  return {
    type: XD_LINE_ITEM_REF_ACTIONS.EDIT_LINE_ITEM_IN_HOLE,
    layerID,
    holeID,
    lineItemID,
    changes,
  }
}

export function removeLineItemFromHole(layerID, holeID, lineItemID) {
  return {
    type: XD_LINE_ITEM_REF_ACTIONS.REMOVE_LINE_ITEM_FROM_HOLE,
    layerID,
    holeID,
    lineItemID,
  }
}

export function addLineItemToItem(layerID, itemID, lineItemID, quantity) {
  return {
    type: XD_LINE_ITEM_REF_ACTIONS.ADD_LINE_ITEM_TO_ITEM,
    layerID,
    itemID,
    lineItemID,
    quantity,
  }
}

export function editLineItemInItem(layerID, itemID, lineItemID, changes) {
  return {
    type: XD_LINE_ITEM_REF_ACTIONS.EDIT_LINE_ITEM_IN_ITEM,
    layerID,
    itemID,
    lineItemID,
    changes,
  }
}

export function removeLineItemFromItem(layerID, itemID, lineItemID) {
  return {
    type: XD_LINE_ITEM_REF_ACTIONS.REMOVE_LINE_ITEM_FROM_ITEM,
    layerID,
    itemID,
    lineItemID,
  }
}

export function addLineItemToProject(lineItemID, quantity) {
  return {
    type: XD_LINE_ITEM_REF_ACTIONS.ADD_LINE_ITEM_TO_PROJECT,
    lineItemID,
    quantity,
  }
}

export function editLineItemInProject(lineItemID, changes) {
  return {
    type: XD_LINE_ITEM_REF_ACTIONS.EDIT_LINE_ITEM_IN_PROJECT,
    lineItemID,
    changes,
  }
}

export function removeLineItemFromProject(lineItemID) {
  return {
    type: XD_LINE_ITEM_REF_ACTIONS.REMOVE_LINE_ITEM_FROM_PROJECT,
    lineItemID,
  }
}
