import Autosave from './autosave';
import Keyboard from './keyboard';
import ConsoleDebugger from './console-debugger';
import XDEvents from './xd-events';

export {
  Autosave,
  Keyboard,
  ConsoleDebugger,
  XDEvents,
};

export default {
  Autosave,
  Keyboard,
  ConsoleDebugger,
  XDEvents,
};
