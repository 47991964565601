import { sendMessage } from "./xd-events";
import * as projectActions from "../actions/project-actions";
import { calculateAreaSize, calculateLineLength } from './xd-math';
import convert from 'convert-units';

const CURRENT_VERSION = 'react-planner:1';

/**
* Received a floor plan to load.
*/
function loadProject(store, floorPlan) {
  console.log("Received floor plan data to load", floorPlan);
  if (!floorPlan)
    return;
  const { version, data } = floorPlan;
  if (version !== CURRENT_VERSION) {
    console.error("Unsupported version:", version, "Expected:", CURRENT_VERSION);
    return;
  }
  const action = projectActions.loadProject(data);
  store.dispatch(action);
}


function saveProject(scene) {
  const floorPlanData = {
    version: CURRENT_VERSION,
    data: scene.toJS(),
    estimates: calculateProjectEstimateFromScene(scene),
  };
  sendMessage({ type: 'save', floorPlanData });
}


function exitProject() {
  if (confirm("Are you sure you want to exit? Any unsaved changes will be lost.")) {
    sendMessage({ type: 'exit' });
  }
}

/**
  @typedef {{
    id: string
    length: number
    unit: string
  }} WallComputed
  @typedef {{
    id: string
    area: number
    unit: string
  }} AreaComputed
  @typedef {{
    id: string
    cost: number          // cost of this line item for all elements
    quantity: number      // total quantity of this line item in this project
    isLabour: boolean     // true if this line item is labour; quantities become "hours", object measurement become irrelevant, baseCost ignored
    walls: Array<{
      id: string          // id of the relevant element
      quantity: number    // qty of this line item on this element
      cost: number        // cost of this line item on this element (qty * costPerUnit)
    }>
    areas: Array<{
      id: string
      quantity: number
      cost: number
    }>
    items: Array<{
      id: string
      quantity: number
      cost: number
    }>
    holes: Array<{
      id: string
      quantity: number
      cost: number
    }>
    projectAdditional: {  // cost of this line item if used as an additional project line item
      quantity: number
      cost: number
    }
  }} LineItemComputed
  @typedef {{
    wallsComputed: Array<WallComputed>
    areasComputed: Array<AreaComputed>
    lineItems: Array<LineItemComputed>
    subTotal: number        // sub-total for whole project (sum of all line items costs)
  }} ProjectEstimate
 */


/**
 * @param {Scene} scene
 * @returns {Array<WallComputed>}
 */
function computeWallsFromScene(scene) {
  const computedLayers = scene.layers.entrySeq().map(([layerID, layer]) => {
    return layer.lines.entrySeq().map(([lineID, line]) => {
      const unit = scene.unit || 'cm';
      const length = calculateLineLength(line, layer, unit);
      return {
        id: lineID,
        length,
        unit,
      };
    }).toJS();
  }).toJS();
  const merged = computedLayers.reduce((acc, layer) => acc.concat(layer), []);
  return merged;
}


/**
 * @param {Scene} scene
 * @returns {Array<AreaComputed>}
 */
function computeAreasFromScene(scene) {
  const computedLayers = scene.layers.entrySeq().map(([layerID, layer]) => {
    return layer.areas.entrySeq().map(([areaID, area]) => {
      const unit = scene.unit || 'cm';
      const areaSize = calculateAreaSize(area, layer, unit);
      return {
        id: areaID,
        area: areaSize,
        unit,
      };
    }).toJS();
  }).toJS();
  const merged = computedLayers.reduce((acc, layer) => acc.concat(layer), []);
  return merged;
}


/**
 * @param {Scene} scene
 * @param {Array<AreaComputed>} areasComputed
 * @param {Array<WallComputed>} wallsComputed
 * @returns {Array<LineItemComputed>}
 */
function computeLineItemsFromScene(scene, areasComputed, wallsComputed) {
  areasComputed = areasComputed || computeAreasFromScene(scene);
  wallsComputed = wallsComputed || computeWallsFromScene(scene);
  const allWalls = scene.layers.entrySeq().map(([layerID, layer]) => layer.lines).flatten(1);
  const allAreas = scene.layers.entrySeq().map(([layerID, layer]) => layer.areas).flatten(1);
  const allItems = scene.layers.entrySeq().map(([layerID, layer]) => layer.items).flatten(1);
  const allHoles = scene.layers.entrySeq().map(([layerID, layer]) => layer.holes).flatten(1);
  const lineItems = scene.lineItems.entrySeq().map(([lineItemID, lineItem]) => {
    const isLabour = lineItem.unit === 'hours';
    const walls = allWalls
      .filter(wall => wall.lineItems.some(ref => ref.id === lineItem.id))
      .map(wall => {
        const quantity =
          wall.lineItems
            .filter(ref => ref.id === lineItem.id)
            .reduce((acc, ref) => {
              if (isLabour) {
                // Ignore wall sides for labour
                return acc + ref.quantity;
              }
              let { quantity, bSideA, bSideB } = ref;
              bSideA = bSideA == null ? true : bSideA;
              bSideB = bSideB == null ? true : bSideB;
              const nSides = (bSideA ? 1 : 0) + (bSideB ? 1 : 0);
              return acc + (quantity * nSides);
            }, 0);
        if (isLabour) {
          const cost = quantity * lineItem.costPerUnit;
          return {
            id: wall.id,
            quantity,
            cost,
          };
        } else {
          const computedWall = wallsComputed.find(w => w.id === wall.id);
          if (!computedWall) throw new Error(`Wall with id ${wall.id} not found in computed walls`);
          const wallLength =
            convert(computedWall.length)
              .from(computedWall.unit)
              .to(lineItem.unit || scene.unit || 'cm');
          const totalCostPerUnit = wallLength * lineItem.costPerUnit;
          const cost = quantity * (lineItem.baseCost + totalCostPerUnit);
          return {
            id: wall.id,
            quantity,
            cost,
          };
        }
      })
      .toJS();
    const areas = allAreas
      .filter(area => area.lineItems.some(ref => ref.id === lineItem.id))
      .map(area => {
        const quantity = area.lineItems.filter(ref => ref.id === lineItem.id).reduce((acc, ref) => acc + ref.quantity, 0);
        if (isLabour) {
          const cost = quantity * lineItem.costPerUnit;
          return {
            id: area.id,
            quantity,
            cost,
          };
        } else {
          const computedArea = areasComputed.find(a => a.id === area.id);
          if (!computedArea) throw new Error(`Area with id ${area.id} not found in computed areas`);
          const areaSize =
            convert(computedArea.area)
              .from(`${computedArea.unit}2`)
              .to(`${lineItem.unit || scene.unit || 'cm'}2`);
          const totalCostPerUnit = areaSize * lineItem.costPerUnit;
          const cost = quantity * (lineItem.baseCost + totalCostPerUnit);
          return {
            id: area.id,
            quantity,
            cost,
          };
        }
      })
      .toJS();
    const items = allItems
      .filter(item => item.lineItems.some(ref => ref.id === lineItem.id))
      .map(item => {
        const quantity = item.lineItems.filter(ref => ref.id === lineItem.id).reduce((acc, ref) => acc + ref.quantity, 0);
        const cost = isLabour ?
          (quantity * lineItem.costPerUnit) :
          (quantity * lineItem.baseCost);
        return {
          id: item.id,
          quantity,
          cost,
        };
      })
      .toJS();
    const holes = allHoles
      .filter(hole => hole.lineItems.some(ref => ref.id === lineItem.id))
      .map(hole => {
        const quantity = hole.lineItems.filter(ref => ref.id === lineItem.id).reduce((acc, ref) => acc + ref.quantity, 0);
        const cost = isLabour ?
          (quantity * lineItem.costPerUnit) :
          (quantity * lineItem.baseCost);
        return {
          id: hole.id,
          quantity,
          cost,
        };
      })
      .toJS();
    const additionalQuantity = scene.lineItemsAdditional.filter(ref => ref.id === lineItem.id).reduce((acc, ref) => acc + ref.quantity, 0);
    const projectAdditional = {
      quantity: additionalQuantity,
      cost: isLabour ?
        (additionalQuantity * lineItem.costPerUnit) :
        (additionalQuantity * lineItem.baseCost)
    };
    const totalQuantity =
      walls.reduce((acc, wall) => acc + wall.quantity, 0) +
      areas.reduce((acc, area) => acc + area.quantity, 0) +
      items.reduce((acc, item) => acc + item.quantity, 0) +
      holes.reduce((acc, hole) => acc + hole.quantity, 0) +
      projectAdditional.quantity;
    const subTotal =
      walls.reduce((acc, wall) => acc + wall.cost, 0) +
      areas.reduce((acc, area) => acc + area.cost, 0) +
      items.reduce((acc, item) => acc + item.cost, 0) +
      holes.reduce((acc, hole) => acc + hole.cost, 0) +
      projectAdditional.cost;
    return {
      id: lineItem.id,
      cost: subTotal,
      quantity: totalQuantity,
      isLabour,
      walls,
      areas,
      items,
      holes,
      projectAdditional,
    };
  });
  return lineItems.toJS();
}


/**
 * @param {Scene} scene
 * @returns {ProjectEstimate}
 */
function calculateProjectEstimateFromScene(scene) {
  const areasComputed = computeAreasFromScene(scene);
  const wallsComputed = computeWallsFromScene(scene);
  const lineItems = computeLineItemsFromScene(scene, areasComputed, wallsComputed);
  const subTotal = lineItems.reduce((acc, lineItem) => acc + lineItem.cost, 0);
  return {
    areasComputed,
    wallsComputed,
    lineItems,
    subTotal,
  };
}


export {
  loadProject,
  saveProject,
  exitProject,
  calculateProjectEstimateFromScene,
};
