import {ElementsFactories} from 'react-planner';

const info = {
  title: 'separator',
  tag: ['separator'],
  description: 'Area separator',
  image: require('./separator.jpg'),
  visibility: {
    catalog: true,
    layerElementsVisible: true
  }
};

const textures = {
};

export default ElementsFactories.SeparatorFactory('separator', info, textures);
