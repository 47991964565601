//
// Existing item picker
//

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as SharedStyle from '../../../../shared-style';
import {TiPlus, TiDelete} from 'react-icons/ti';
import {FaSearch} from 'react-icons/fa';
import {FormTextInput} from '../../../style/export';
import XDLineItemListItem from './xd-line-item-list-item';
import XDPagination from '../../../style/xd-pagination';
import { Seq } from 'immutable';

const styleAddLabel = {fontSize: '10px', margin: '0 0.5em'};
const labelStyle = {fontSize: '1.3em', cursor: 'pointer'};

export default class XDLineItemPicker extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      searchFilter: '',
      page: 1,
      limit: 20,
    };
  }

  getFilteredLineItems() {
    let {
      props: {state, excludeIDs},
      state: {searchFilter, page, limit},
    } = this;
    let lineItems = state.scene.lineItems.entrySeq();
    if (excludeIDs) {
      lineItems = lineItems.filter(([id, lineItem]) => !excludeIDs.includes(lineItem.id));
    }
    searchFilter = searchFilter.trim().toLowerCase();
    if (searchFilter) {
      lineItems = lineItems.filter(
        ([id, lineItem]) =>
          lineItem.get('name')
            .toLowerCase()
            .includes(searchFilter)
      );
    }
    const total = lineItems.count();
    if (limit) {
      const offset = (page - 1) * limit;
      lineItems = lineItems.slice(offset, offset + limit);
    }
    return { lineItems, totalLineItems: total };
  }

  onPaginate(page) {
    this.setState({ page });
  }

  render() {
    let {
      props: {state: appState, onAdd, onCancel},
      state: {searchFilter, page, limit},
    } = this;
    let { lineItems, totalLineItems } = this.getFilteredLineItems();
    return (
      <div>
        <table style={{
          width: '100%',
          marginBottom: '0.5em',
        }}>
          <tbody>
            <tr>
              <td>Add existing line item</td>
              <td style={{ textAlign: 'right' }}>
                <button onClick={() => onCancel()}>
                  <TiDelete style={styleAddLabel}/>
                  <span>Cancel</span>
                </button>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <FaSearch style={{margin: '0.5em'}}/>
                  <FormTextInput type="text"
                    placeholder="Search..."
                    style={{width:'100%'}}
                    value={searchFilter}
                    onChange={e => this.setState({ searchFilter: e.target.value || '' })}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div style={{ overflowY: 'auto', maxHeight: '400px' }}>
          {
            lineItems.count() > 0 ?

              lineItems.map(([id, lineItem]) => (
                <XDLineItemListItem
                  key={id}
                  state={appState}
                  style={{borderTop: '1px solid #222'}}
                  lineItem={lineItem}
                  onClick={() => onAdd(id)}
                />
              )) :

              <div style={{
                padding: '1em',
                textAlign: 'center',
                borderTop: '1px solid #222',
              }}>
                <div> No line items available</div>
                {
                  searchFilter.trim() &&
                  <div>Search filter: "{searchFilter.trim()}"</div>
                }
              </div>
          }
        </div>
        {
          totalLineItems > 0 &&
            <XDPagination
              page={page}
              pageSize={limit}
              total={totalLineItems}
              onChange={page => this.onPaginate(page)}
              style={{
                margin: '0.5em 0',
              }}
            />
        }
      </div>
    );
  }
}

XDLineItemPicker.propTypes = {
  state: PropTypes.object.isRequired,
  excludeIDs: PropTypes.array,
  onAdd: PropTypes.func,
  onCancel: PropTypes.func,
};

XDLineItemPicker.contextTypes = {
  catalog: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
};
