//
// Import from catalog
//

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as SharedStyle from '../../../../shared-style';
import {TiPlus, TiDelete} from 'react-icons/ti';
import {FaSearch} from 'react-icons/fa';
import {Button, FormTextInput} from '../../../style/export';
import { getLabourCategories, getMaterialCategories, searchLabour, searchMaterials } from '../../../../utils/xd-directus';
import { List, Record, fromJS } from 'immutable';
import XDLineItemListItem from './xd-line-item-list-item';
import XDPagination from '../../../style/xd-pagination';

const styleAddLabel = {fontSize: '10px', margin: '0 0.5em'};
const labelStyle = {fontSize: '1.3em', cursor: 'pointer'};
const rowMargin = {marginTop: '0.5rem', marginBottom: '0.5rem'};

export default class XDLineItemCatalog extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      page: 1,
      limit: 20,

      filterName: '',
      /** @type {string[]} */
      filterCategories: [],
      /** @type {'material'|'labour'} */
      filterType: 'material',

      /** @type {string[]} */
      fetchedCategories: new List(),
      /** @type {any[]} */
      fetchedItems: new List(),
      /** @type {number} */
      fetchedItemsTotal: 0,
    };
    this.fetchAvailableCategories();
    this.fetchLineItems();
  }

  fetchLineItems() {
    let { page, limit, filterName, filterCategories, filterType } = this.state;
    if (filterCategories.length === 0) {
      filterCategories = undefined; // disable category filter
    }
    switch (filterType) {
      case 'labour':
        searchLabour(filterName, filterCategories, limit, page).then(data => {
          this.setState({
            fetchedItems: fromJS(data.items.map(li => ({
              name: li.name,
              baseCost: li.cost_base,
              costPerUnit: li.cost_per_hour,
              unit: 'hours',
            }))),
            fetchedItemsTotal: data.total,
          });
        });
        break;
      case 'material':
        searchMaterials(filterName, filterCategories, limit, page).then(data => {
          this.setState({
            fetchedItems: fromJS(data.items.map(li => ({
              name: li.name,
              baseCost: li.cost_base,
              costPerUnit: li.cost_per_unit,
              unit: li.cost_unit,
            }))),
            fetchedItemsTotal: data.total,
          });
        });
        break;
      default:
        throw new Error(`Invalid filterType: ${filterType}`);
    }
  }

  debouncedFetchLineItems(ms = 300) {
    clearTimeout(this.debouncedFetchLineItems.timeout);
    this.debouncedFetchLineItems.timeout = setTimeout(() => {
      this.fetchLineItems();
    }, ms);
  }

  fetchAvailableCategories() {
    let { filterType } = this.state;
    switch (filterType) {
      case 'labour':
        getLabourCategories().then(data => {
          this.setState({
            fetchedCategories: data,
          });
        });
        break;
      case 'material':
        getMaterialCategories().then(data => {
          this.setState({
            fetchedCategories: data,
          });
        });
        break;
      default:
        throw new Error(`Invalid filterType: ${filterType}`);
    }
  }

  getPagination() {
    const { page, limit, fetchedItemsTotal } = this.state;
    return {
      page: page,
      limit: limit,
      totalItems: fetchedItemsTotal,
      totalPages: Math.ceil(fetchedItemsTotal / limit),
    };
  }

  onPaginate(page) {
    this.setState({ page });
    this.debouncedFetchLineItems();
  }

  clearFetched() {
    this.setState({
      fetchedCategories: new List(),
      fetchedItems: new List(),
      page: 0,
      filterCategories: [],
    });
  }

  toggleCategory(category, autoFetch = true) {
    const { filterCategories } = this.state;
    const index = filterCategories.indexOf(category);
    if (index === -1) {
      this.setState({
        filterCategories: filterCategories.concat([category]),
      });
    } else {
      this.setState({
        filterCategories: filterCategories.filter(c => c !== category),
      });
    }
    if (autoFetch) {
      this.debouncedFetchLineItems();
    }
  }

  toggleAllCategories(autoFetch = true) {
    // If all categories are already selected, deselect all
    // If some categories are selected, select all
    // If no categories are selected, select all
    const { filterCategories, fetchedCategories } = this.state;
    const allSelected = filterCategories.length === fetchedCategories.length;
    if (allSelected) {
      this.setState({
        filterCategories: [],
      });
    } else {
      this.setState({
        filterCategories: fetchedCategories,
      });
    }
    if (autoFetch) {
      this.debouncedFetchLineItems();
    }
  }

  invertAllCategories(autoFetch = true) {
    // Invert all category selections
    const { filterCategories, fetchedCategories } = this.state;
    const newCategories = fetchedCategories.filter(c => !filterCategories.includes(c));
    this.setState({
      filterCategories: newCategories,
    });
    if (autoFetch) {
      this.debouncedFetchLineItems();
    }
  }

  /**
   * @param {'material'|'labour'} type
   */
  changeType(type) {
    const { filterType: prevType } = this.state;
    if (type === prevType)
      return;
    switch (type) {
      case 'material':
      case 'labour':
        this.clearFetched();
        this.setState({ filterType: type });
        setTimeout(() => {
          this.fetchAvailableCategories();
          this.debouncedFetchLineItems();
        });
        break;
      default:
        throw new Error(`Invalid type: ${type}`);
    }
  }

  render() {
    let {
      props: {state: appState, onAdd, onCancel},
      state: {
        filterName, filterCategories, filterType,
        fetchedCategories, fetchedItems,
      },
    } = this;
    const { limit, page, totalItems, totalPages } = this.getPagination();
    const lineItems = fetchedItems.entrySeq();
    return (
      <div>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          <div>Import from Catalog</div>
          <div style={{ textAlign: 'right' }}>
            <button onClick={() => onCancel()}>
              <TiDelete style={styleAddLabel}/>
              <span>Cancel</span>
            </button>
          </div>
        </div>

        <div style={{...rowMargin, display: 'flex', flexDirection: 'row', gap: '0.5em'}}>
          <Button
            onClick={() => this.changeType('material')}
            style={{
              backgroundColor: filterType === 'material' ? SharedStyle.SECONDARY_COLOR.main : null,
            }}
          >
            Materials
          </Button>
          <Button
            onClick={() => this.changeType('labour')}
            style={{
              backgroundColor: filterType === 'labour' ? SharedStyle.SECONDARY_COLOR.main : null,
            }}
          >
            Labour
          </Button>
        </div>

        <div style={{...rowMargin,  display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Button
            onClick={() => this.toggleAllCategories(true)}
            size="small"
            style={{ backgroundColor: 'transparent', border: 'none', color: SharedStyle.COLORS.white }}
            styleHover={{ backgroundColor: 'transparent', border: 'none', color: SharedStyle.SECONDARY_COLOR.main }}
          >
            Toggle All
          </Button>
          <Button
            onClick={() => this.invertAllCategories(true)}
            size="small"
            style={{ backgroundColor: 'transparent', border: 'none', color: SharedStyle.COLORS.white }}
            styleHover={{ backgroundColor: 'transparent', border: 'none', color: SharedStyle.SECONDARY_COLOR.main }}
          >
            Invert
          </Button>
        </div>

        <div style={{
          ...rowMargin,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          maxHeight: '100px',
        }}>
          {
            fetchedCategories.map((category, i) => {
              const isActive = filterCategories.includes(category);
              return (
                <Button
                  key={i}
                  title={category}
                  onClick={() => this.toggleCategory(category, true)}
                  style={{
                    backgroundColor: isActive ? SharedStyle.SECONDARY_COLOR.main : null,
                    textTransform: 'capitalize',
                    flex: '1 0 auto',
                    width: 'auto',
                  }}
                  styleHover={{
                    backgroundColor: isActive ? SharedStyle.SECONDARY_COLOR.main : null,
                    textTransform: 'capitalize',
                    flex: '1 0 auto',
                    width: 'auto',
                  }}
                  size="small"
                >
                  {category}
                </Button>
              );
            })
          }
        </div>

        <div style={{...rowMargin,  display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <FaSearch style={{margin: '0.5em'}}/>
          <FormTextInput type="text"
            placeholder="Search..."
            style={{width:'100%'}}
            value={filterName}
            onChange={e => {
              const val = e.target.value || '';
              this.setState({ filterName: val });
              this.debouncedFetchLineItems();
            }}
          />
        </div>

        <div style={{ overflowY: 'auto', maxHeight: '400px' }}>
          {
            lineItems.count() > 0 ?

              lineItems.map(([id, lineItem]) => (
                <XDLineItemListItem
                  key={id}
                  state={appState}
                  style={{borderTop: '1px solid #222'}}
                  lineItem={lineItem}
                  onClick={() => onAdd(lineItem, filterType)}
                />
              )) :

              <div style={{
                padding: '1em',
                textAlign: 'center',
                borderTop: '1px solid #222',
              }}>
                <div> No line items found</div>
                {
                  filterName.trim() &&
                  <div>Search filter: "{filterName.trim()}"</div>
                }
              </div>
          }
        </div>
        {
          lineItems.count() > 0 &&
            <XDPagination
              page={page}
              pageSize={limit}
              total={totalItems}
              onChange={page => this.onPaginate(page)}
              style={{
                margin: '0.5em 0',
              }}
            />
        }
      </div>
    );
  }
}

XDLineItemCatalog.propTypes = {
  state: PropTypes.object.isRequired,
  onAdd: PropTypes.func,
  onCancel: PropTypes.func,
};

XDLineItemCatalog.contextTypes = {
  catalog: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
};
