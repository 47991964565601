import { XDUserNotes } from '../class/export';
import { XD_USER_NOTES } from '../xd-constants';

export default function (state, action) {
  switch (action.type) {

    case XD_USER_NOTES.UPDATE_LINE_USER_NOTES:
      return XDUserNotes.updateLineNotes(state, action.layerID, action.lineID, action.userNotes).updatedState;

    case XD_USER_NOTES.UPDATE_AREA_USER_NOTES:
      return XDUserNotes.updateAreaNotes(state, action.layerID, action.areaID, action.userNotes).updatedState;

    case XD_USER_NOTES.UPDATE_HOLE_USER_NOTES:
      return XDUserNotes.updateHoleNotes(state, action.layerID, action.holeID, action.userNotes).updatedState;

    case XD_USER_NOTES.UPDATE_ITEM_USER_NOTES:
      return XDUserNotes.updateItemNotes(state, action.layerID, action.itemID, action.userNotes).updatedState;

    default:
      return state;
  }
}
