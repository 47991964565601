import { Map } from 'immutable';
import {
  XDLineItem as LineItem,
  XDLineItemRef as LineItemRef
} from '../xd-models';

class XDLineItemRef {

  static addTo( state, layerID, objectMapName, objectID, lineItemID, quantity ) {
    let lineItem = state.getIn(['scene', 'lineItems', lineItemID]);
    if (!lineItem) {
      console.warn(`Line item with id ${lineItemID} not found - create it first?`);
      return { updatedState: state };
    }
    let ref = new LineItemRef({
      id: lineItem.id,
      quantity: quantity || 1
    });
    state = state.updateIn(['scene', 'layers', layerID, objectMapName, objectID, 'lineItems'], list => list.push(ref));
    return { updatedState: state };
  }

  static setAttributes( state, layerID, objectMapName, objectID, lineItemID, attributes ) {
    let list = state.getIn(['scene', 'layers', layerID, objectMapName, objectID, 'lineItems']);
    if (!list || !list.some(li => li.id === lineItemID)) {
      console.warn(`Line item with id ${lineItemID} not found in object ${objectID}`);
      return { updatedState: state };
    }
    state = state.setIn(['scene', 'layers', layerID, objectMapName, objectID, 'lineItems'], list.map(li => {
      if (li.id === lineItemID) {
        Object.entries(attributes).forEach(([key, value]) => {
          li = li.set(key, value);
        });
      }
      return li;
    }));
    return { updatedState: state };
  }

  static removeFrom( state, layerID, objectMapName, objectID, lineItemID ) {
    let list = state.getIn(['scene', 'layers', layerID, objectMapName, objectID, 'lineItems']);
    if (!list || !list.some(li => li.id === lineItemID)) {
      console.warn(`Line item with id ${lineItemID} not found in object ${objectID}`);
      return { updatedState: state };
    }
    state = state.setIn(['scene', 'layers', layerID, objectMapName, objectID, 'lineItems'], list.filterNot(li => li.id === lineItemID));
    return { updatedState: state };
  }

  static addToProject( state, lineItemID, quantity ) {
    let lineItem = state.getIn(['scene', 'lineItems', lineItemID]);
    if (!lineItem) {
      console.warn(`Line item with id ${lineItemID} not found - create it first?`);
      return { updatedState: state };
    }
    let ref = new LineItemRef({
      id: lineItem.id,
      quantity: quantity || 1
    });
    state = state.updateIn(['scene', 'lineItemsAdditional'], list => list.push(ref));
    return { updatedState: state };
  }

  static setAttributesForProject( state, lineItemID, attributes ) {
    let list = state.getIn(['scene', 'lineItemsAdditional']);
    if (!list || !list.some(li => li.id === lineItemID)) {
      console.warn(`Line item with id ${lineItemID} not found in project`);
      return { updatedState: state };
    }
    state = state.setIn(['scene', 'lineItemsAdditional'], list.map(li => {
      if (li.id === lineItemID) {
        Object.entries(attributes).forEach(([key, value]) => {
          li = li.set(key, value);
        });
      }
      return li;
    }));
    return { updatedState: state };
  }

  static removeFromProject( state, lineItemID ) {
    let list = state.getIn(['scene', 'lineItemsAdditional']);
    if (!list || !list.some(li => li.id === lineItemID)) {
      console.warn(`Line item with id ${lineItemID} not found in project`);
      return { updatedState: state };
    }
    state = state.setIn(['scene', 'lineItemsAdditional'], list.filterNot(li => li.id === lineItemID));
    return { updatedState: state };
  }

  static addToLine( state, layerID, lineID, lineItemID, quantity ) {
    return this.addTo( state, layerID, 'lines', lineID, lineItemID, quantity );
  }

  static setAttributesForLine( state, layerID, lineID, lineItemID, attributes ) {
    return this.setAttributes( state, layerID, 'lines', lineID, lineItemID, attributes );
  }

  static removeFromLine( state, layerID, lineID, lineItemID ) {
    return this.removeFrom( state, layerID, 'lines', lineID, lineItemID );
  }

  static addToArea( state, layerID, areaID, lineItemID, quantity ) {
    return this.addTo( state, layerID, 'areas', areaID, lineItemID, quantity );
  }

  static setAttributesForArea( state, layerID, areaID, lineItemID, attributes ) {
    return this.setAttributes( state, layerID, 'areas', areaID, lineItemID, attributes );
  }

  static removeFromArea( state, layerID, areaID, lineItemID ) {
    return this.removeFrom( state, layerID, 'areas', areaID, lineItemID );
  }

  static addToHole( state, layerID, holeID, lineItemID, quantity ) {
    return this.addTo( state, layerID, 'holes', holeID, lineItemID, quantity );
  }

  static setAttributesForHole( state, layerID, holeID, lineItemID, attributes ) {
    return this.setAttributes( state, layerID, 'holes', holeID, lineItemID, attributes );
  }

  static removeFromHole( state, layerID, holeID, lineItemID ) {
    return this.removeFrom( state, layerID, 'holes', holeID, lineItemID );
  }

  static addToItem( state, layerID, itemID, lineItemID, quantity ) {
    return this.addTo( state, layerID, 'items', itemID, lineItemID, quantity );
  }

  static setAttributesForItem( state, layerID, itemID, lineItemID, attributes ) {
    return this.setAttributes( state, layerID, 'items', itemID, lineItemID, attributes );
  }

  static removeFromItem( state, layerID, itemID, lineItemID ) {
    return this.removeFrom( state, layerID, 'items', itemID, lineItemID );
  }

}

export { XDLineItemRef as default };
