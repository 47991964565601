import IDBroker from "./id-broker";

/**
 * @param {string} query
 * @param {any} variables
 * @returns {Promise<any>}
 */
export function gqlQuery(query, variables) {
  const transactionID = IDBroker.acquireID();
  const timestamp = performance.now();
  DIRECTUS_DEBUG && console.debug(`[gqlQuery:${transactionID}] Request\n`, {query, variables});
  // TODO: hardcoded yuckiness
  return fetch(DIRECTUS_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${DIRECTUS_TOKEN}`,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  })
    .then(res => res.json())
    .then(json => {
      const elapsedMsec = performance.now() - timestamp;
      DIRECTUS_DEBUG && console.debug(`[gqlQuery:${transactionID}] Response (took ${elapsedMsec.toFixed(2)} msec)\n`, json);
      if (json.errors) {
        DIRECTUS_DEBUG && console.error(json.errors);
        throw new Error(json.errors.map(x => x.message).join('\n'));
      }
      return json;
    });
}

/**
 * @returns {Promise<string[]>}
 */
export function getLabourCategories() {
  const query = `
    query LabourCategories {
      labour_item_aggregated(groupBy: "category") {
        group
      }
    }
  `;
  return gqlQuery(query).then(({ data }) => (
    data.labour_item_aggregated
      .map(x => x.group.category)
      .filter(x => !!x)
  ));
}

/**
 * @returns {Promise<string[]>}
 */
export function getMaterialCategories() {
  const query = `
    query MaterialCategories {
      material_item_aggregated(groupBy: "category") {
        group
      }
    }
  `;
  return gqlQuery(query).then(({ data }) => (
    data.material_item_aggregated
      .map(x => x.group.category)
      .filter(x => !!x)
  ));
}

/**
 * @param {string?} filterName
 * @param {string[]?} categories
 * @param {number?} limit
 * @param {number?} page
 * @returns {Promise<{ items: any[], total: number }>}
 */
export function searchLabour(filterName, categories, limit, page) {
  const query = `
    query SearchLabour(
      ${ filterName ? '$filterName: String = ""' : '' }
      ${ categories ? '$categories: [String]' : '' }
      $limit: Int = 20
      $page: Int = 1
    ) {
      labour_item(
        filter: {
          ${ filterName ? 'name: { _icontains: $filterName }' : '' }
          ${ categories ? 'category: { _in: $categories }' : '' }
        }
        limit: $limit
        page: $page
      ) {
        id
        name
        category
        cost_base
        cost_per_hour
        date_created
        date_updated
        sort
        status
      }
      labour_item_aggregated(filter: {
          ${ filterName ? 'name: { _icontains: $filterName }' : '' }
          ${ categories ? 'category: { _in: $categories }' : '' }
      }) {
        count { id }
      }
    }
  `;
  const vars = { filterName, categories, limit, page };
  return gqlQuery(query, vars).then(({ data }) => ({
    items: data.labour_item,
    total: data.labour_item_aggregated[0].count.id,
  }));
}

/**
 * @param {string?} filterName
 * @param {string[]?} categories
 * @param {number?} limit
 * @param {number?} page
 * @returns {Promise<{ items: any[], total: number }>}
 */
export function searchMaterials(filterName, categories, limit, page) {
  const query = `
    query SearchMaterials(
      ${ filterName ? '$filterName: String = ""' : '' }
      ${ categories ? '$categories: [String]' : '' }
      $limit: Int = 20
      $page: Int = 1
    ) {
      material_item(
        filter: {
          ${ filterName ? 'name: { _icontains: $filterName }' : '' }
          ${ categories ? 'category: { _in: $categories }' : '' }
        }
        limit: $limit
        page: $page
      ) {
        id
        name
        category
        cost_base
        cost_per_unit
        cost_unit
        date_updated
        date_created
        status
      }
      material_item_aggregated(filter: {
          ${ filterName ? 'name: { _icontains: $filterName }' : '' }
          ${ categories ? 'category: { _in: $categories }' : '' }
      }) {
        count { id }
      }
    }
  `;
  const vars = { filterName, categories, limit, page };
  return gqlQuery(query, vars)
  .then(({ data }) => ({
    items: data.material_item,
    total: data.material_item_aggregated[0].count.id,
  }));
}
