import { IDBroker } from '../utils/export';

import {
  XDLineItem as LineItem,
  XDLineItemRef as LineItemRef
} from '../xd-models';

class XDLineItem {

  static create( state, baseCost, costPerUnit, unit, name = '', overrideID = '') {
    let itemID = overrideID || IDBroker.acquireID();
    let item = new LineItem({
      id: itemID,
      name,
      baseCost,
      costPerUnit,
      unit
    });
    state = state.setIn(['scene', 'lineItems', itemID], item);
    return { updatedState: state, item };
  }


  static remove( state, lineItemID ) {
    state = state.deleteIn(['scene', 'lineItems', lineItemID]);
    return { updatedState: state };
  }


  static setAttributes( state, lineItemID, itemAttributes) {
    state = state.mergeIn(['scene', 'lineItems', lineItemID], itemAttributes);
    return { updatedState: state };
  }

}

export { XDLineItem as default };
