import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Map, List, fromJS} from 'immutable';
import * as SharedStyle from '../../../shared-style';
import {TiPlus, TiPencil, TiTrash} from 'react-icons/ti';
import {FaTrash, FaEye, FaLink, FaUnlink} from 'react-icons/fa';
import { IDBroker } from '../../../utils/export';
import {FormTextInput, FormNumberInput, Button} from '../../style/export';
import XDLineItemEditor from './xd-line-items-editor/xd-line-item-editor';
import XDLineItemPicker from './xd-line-items-editor/xd-line-item-picker';

const styleHoverColor = {color: SharedStyle.SECONDARY_COLOR.main};
const styleAddLabel = {fontSize: '10px', marginLeft: '5px'};
const labelStyle = {fontSize: '1.3em', cursor: 'pointer', textAlign: 'center'};
const labelHoverStyle = {...labelStyle, ...styleHoverColor};

export default class XDLineItemsEditor extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = this.initState(this.props.element, this.props.layer, this.props.state);
  }

  componentWillReceiveProps({ element, layer, state }) {
    let scene = this.props.state.get('scene');
    let selectedLayer = scene.getIn(['layers', scene.get('selectedLayer')]);
    if( selectedLayer.hashCode() !== layer.hashCode() )
      this.setState(this.initState(this.props.element, this.props.layer, this.props.state));
  }

  componentWillUnmount() {
    let { editingLineItem, editingLineItemIsNew } = this.state;
    if (editingLineItemIsNew) {
      // New item was being edited, but component is unmounting
      // Delete the new item from scene
      console.debug('Unmounting xd-line-items-editor - deleting new item', editingLineItem);
      let { lineItemActions } = this.context;
      lineItemActions.removeLineItem(editingLineItem);
    }
  }

  initState(element, layer, state) {
    element = typeof element.misc === 'object' ? element.set('misc', new Map(element.misc)) : element;
    const type = element.prototype; // 'items', 'lines', 'holes', 'areas'
    return {
      newItemHover: false,
      addItemHover: false,
      addingExistingLineItem: false,
      editingLineItem: null,
      editingLineItemIsNew: false, // When editing and true, onSave will add line item ref to element
    };
  }

  reset() {
    this.setState(this.initState(this.props.element, this.props.layer, this.props.state));
  }

  createNewItem() {
    let { lineItemActions } = this.context;
    let newID = IDBroker.acquireID();
    lineItemActions.createLineItem(0, 0, undefined, undefined, newID);
    return newID;
  }

  updateRefField(lineItemRefID, field, value) {
    let { lineItemRefActions } = this.context;
    let { layer, element } = this.props;
    let changes = {
      [field]: value
    };
    switch (element.prototype) {
      case 'items':
        lineItemRefActions.editLineItemInItem(layer.id, element.id, lineItemRefID, changes);
        break;
      case 'lines':
        lineItemRefActions.editLineItemInLine(layer.id, element.id, lineItemRefID, changes);
        break;
      case 'holes':
        lineItemRefActions.editLineItemInHole(layer.id, element.id, lineItemRefID, changes);
        break;
      case 'areas':
        lineItemRefActions.editLineItemInArea(layer.id, element.id, lineItemRefID, changes);
        break;
      default:
        console.warn('Attempted to add line item ref to unknown prototype', element.prototype);
        break;
    }
  }

  appendLineItem(lineItemID) {
    let {
      context: {lineItemRefActions},
      props: {element, layer},
    } = this;
    switch (element.prototype) {
      case 'items':
        lineItemRefActions.addLineItemToItem(layer.id, element.id, lineItemID);
        break;
      case 'lines':
        lineItemRefActions.addLineItemToLine(layer.id, element.id, lineItemID);
        break;
      case 'holes':
        lineItemRefActions.addLineItemToHole(layer.id, element.id, lineItemID);
        break;
      case 'areas':
        lineItemRefActions.addLineItemToArea(layer.id, element.id, lineItemID);
        break;
      default:
        console.warn('Attempted to add line item ref to unknown prototype', element.prototype);
        break;
    }
  }

  removeRef(lineItemID) {
    let {
      context: {lineItemRefActions},
      props: {element, layer},
    } = this;
    switch (element.prototype) {
      case 'items':
        lineItemRefActions.removeLineItemFromItem(layer.id, element.id, lineItemID);
        break;
      case 'lines':
        lineItemRefActions.removeLineItemFromLine(layer.id, element.id, lineItemID);
        break;
      case 'holes':
        lineItemRefActions.removeLineItemFromHole(layer.id, element.id, lineItemID);
        break;
      case 'areas':
        lineItemRefActions.removeLineItemFromArea(layer.id, element.id, lineItemID);
        break;
      default:
        console.warn('Attempted to remove line item ref to unknown prototype', element.prototype);
        break;
    }
  }

  renderLineItem(lineItemRef) {
    let { state, element, layer } = this.props;
    let lineItem = state.getIn(['scene', 'lineItems', lineItemRef.id]);
    if (!lineItem) {
      console.warn(`Invalid line item reference (id=${lineItemRef.id})`, lineItemRef, lineItem);
      return null;
    }
    const isLabour = lineItem.unit === 'hours';
    let tblStyle = {
      width: '100%',
      borderBottom: '1px #222 solid',
      marginBottom: '0.5em',
      paddingBottom: '0.5em',
    };
    return (
      <table key={lineItemRef.id} style={tblStyle}>
        <tbody>
          <tr>
            <td>Name</td>
            <td>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '100%',
              }}>
                <span style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}>
                  {lineItem.name}
                </span>
                <div>
                  <button
                    title='Remove this item'
                    onClick={() => {
                      if (confirm(`Remove line item "${lineItem.name}"?`)) {
                        this.removeRef(lineItemRef.id);
                      }
                    }}
                  >
                    <TiTrash />
                  </button>
                  <button
                    title='Edit this item'
                    onClick={ () => this.setState({editingLineItem: lineItemRef.id, editingLineItemIsNew: false}) }
                  >
                    <TiPencil />
                  </button>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>{ isLabour ? "Hours" : "Quantity" }</td>
            <td>
              <FormNumberInput
                value={lineItemRef.quantity}
                state={state}
                onChange={e => this.updateRefField(lineItemRef.id, 'quantity', e.target.value)}
                immediate={true}
              />
            </td>
          </tr>
          {
            !isLabour && element.prototype === 'lines' &&
            <tr>
              <td>Apply on</td>
              <td>
                <input type='checkbox'
                  id={`lineItemRef-${lineItemRef.id}-bSideA`}
                  checked={lineItemRef.bSideA == null ? true : lineItemRef.bSideA}
                  onChange={e => this.updateRefField(lineItemRef.id, 'bSideA', e.target.checked)}
                />
                <label htmlFor={`lineItemRef-${lineItemRef.id}-bSideA`}>Side A</label>
                &nbsp;
                <input type='checkbox'
                  id={`lineItemRef-${lineItemRef.id}-bSideB`}
                  checked={lineItemRef.bSideB == null ? true : lineItemRef.bSideB}
                  onChange={e => this.updateRefField(lineItemRef.id, 'bSideB', e.target.checked)}
                />
                <label htmlFor={`lineItemRef-${lineItemRef.id}-bSideB`}>Side B</label>
              </td>
            </tr>
          }
        </tbody>
      </table>
    );
  }

  renderListState() {
    let {
      state: {newItemHover, addItemHover},
      props: {element},
    } = this;
    return <div>
      {
        (element.lineItems.size > 0) &&
          element.lineItems.entrySeq().map(([lineItemID, lineItemRef]) => {
            return this.renderLineItem(lineItemRef);
          })
      }
      <table style={{width:'100%', marginTop: '0.1em'}}>
        <tbody>
          <tr>
            <td
              style={ !newItemHover ? labelStyle : labelHoverStyle }
              onMouseOver={ () => this.setState({newItemHover: true}) }
              onMouseOut={ () => this.setState({newItemHover: false}) }
              onClick={ e => {
                const newID = this.createNewItem()
                this.setState({editingLineItem: newID, editingLineItemIsNew: true, newItemHover: false});
              }}
            >
              <TiPlus />
              <b style={styleAddLabel}>New Item</b>
            </td>
            <td
              style={ !addItemHover ? labelStyle : labelHoverStyle }
              onMouseOver={ () => this.setState({addItemHover: true}) }
              onMouseOut={ () => this.setState({addItemHover: false}) }
              onClick={ e => this.setState({addingExistingLineItem: true, addItemHover: false}) }
            >
              <TiPlus />
              <b style={styleAddLabel}>Add Existing</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  }

  renderEditState() {
    let {
      state: {editingLineItem, editingLineItemIsNew},
      context: {lineItemActions},
      props: {state: appState},
    } = this;
    return <XDLineItemEditor
      state={appState}
      lineItemID={editingLineItem}
      isNew={!!editingLineItemIsNew}
      onSave={(formData, bAsCopy) => {
        let itemID = editingLineItem;
        if (bAsCopy) {
          // Requesting new copy
          // Delete old line item ref on this element
          this.removeRef(editingLineItem);
          // Replace it with a new one
          itemID = this.createNewItem();
          this.appendLineItem(itemID);
        }
        lineItemActions.editLineItem(itemID, formData);
        if (editingLineItemIsNew) {
          // New line items automatically added to element
          this.appendLineItem(itemID);
        }
        this.setState({editingLineItem: null, editingLineItemIsNew: false});
      }}
      onCancel={() => {
        if (editingLineItemIsNew) {
          // New item was being edited, but was cancelled
          // Delete the new item from scene
          let { lineItemActions } = this.context;
          lineItemActions.removeLineItem(editingLineItem);
        }
        this.setState({editingLineItem: null, editingLineItemIsNew: false})
      }}
    />
  }

  renderPickerState() {
    let {
      props: {state: appState, element},
    } = this;
    return <XDLineItemPicker
      state={appState}
      excludeIDs={element.lineItems.entrySeq().map(([idx, li]) => li.id).toArray()}
      onAdd={lineItemID => {
        this.appendLineItem(lineItemID);
        this.setState({addingExistingLineItem: false});
      }}
      onCancel={ () => this.setState({addingExistingLineItem: false}) }
    />
  }

  render() {
    let {
      state: {addingExistingLineItem, editingLineItem},
    } = this;
    const uiState =
      (
        !editingLineItem &&
        !addingExistingLineItem &&
        'list'
      ) || (
        editingLineItem &&
        !addingExistingLineItem &&
        'edit'
      ) || (
        addingExistingLineItem &&
        !editingLineItem &&
        'picker'
      ) ||
      'unknown';
    switch (uiState) {
      case 'list':
        return this.renderListState();
      case 'edit':
        return this.renderEditState();
      case 'picker':
        return this.renderPickerState();
      default:
        console.warn(`Unhandled UI state "${uiState}" for XDLineItemsEditor`, this.state);
        return null;
    }
  }
}

XDLineItemsEditor.propTypes = {
  state: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired
};

XDLineItemsEditor.contextTypes = {
  lineItemActions: PropTypes.object.isRequired,
  lineItemRefActions: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
};
