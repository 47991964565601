import { XDLineItemRef } from '../class/export';
import {
  XD_LINE_ITEM_REF_ACTIONS
} from '../xd-constants';

export default function (state, action) {
  switch (action.type) {

    case XD_LINE_ITEM_REF_ACTIONS.ADD_LINE_ITEM_TO_AREA:
      return XDLineItemRef.addToArea(state, action.layerID, action.areaID, action.lineItemID, action.quantity).updatedState;

    case XD_LINE_ITEM_REF_ACTIONS.EDIT_LINE_ITEM_IN_AREA:
      return XDLineItemRef.setAttributesForArea(state, action.layerID, action.areaID, action.lineItemID, action.changes).updatedState;

    case XD_LINE_ITEM_REF_ACTIONS.REMOVE_LINE_ITEM_FROM_AREA:
      return XDLineItemRef.removeFromArea(state, action.layerID, action.areaID, action.lineItemID).updatedState;

    case XD_LINE_ITEM_REF_ACTIONS.ADD_LINE_ITEM_TO_LINE:
      return XDLineItemRef.addToLine(state, action.layerID, action.lineID, action.lineItemID, action.quantity).updatedState;

    case XD_LINE_ITEM_REF_ACTIONS.EDIT_LINE_ITEM_IN_LINE:
      return XDLineItemRef.setAttributesForLine(state, action.layerID, action.lineID, action.lineItemID, action.changes).updatedState;

    case XD_LINE_ITEM_REF_ACTIONS.REMOVE_LINE_ITEM_FROM_LINE:
      return XDLineItemRef.removeFromLine(state, action.layerID, action.lineID, action.lineItemID).updatedState;

    case XD_LINE_ITEM_REF_ACTIONS.ADD_LINE_ITEM_TO_HOLE:
      return XDLineItemRef.addToHole(state, action.layerID, action.holeID, action.lineItemID, action.quantity).updatedState;

    case XD_LINE_ITEM_REF_ACTIONS.EDIT_LINE_ITEM_IN_HOLE:
      return XDLineItemRef.setAttributesForHole(state, action.layerID, action.holeID, action.lineItemID, action.changes).updatedState;

    case XD_LINE_ITEM_REF_ACTIONS.REMOVE_LINE_ITEM_FROM_HOLE:
      return XDLineItemRef.removeFromHole(state, action.layerID, action.holeID, action.lineItemID).updatedState;

    case XD_LINE_ITEM_REF_ACTIONS.ADD_LINE_ITEM_TO_ITEM:
      return XDLineItemRef.addToItem(state, action.layerID, action.itemID, action.lineItemID, action.quantity).updatedState;

    case XD_LINE_ITEM_REF_ACTIONS.EDIT_LINE_ITEM_IN_ITEM:
      return XDLineItemRef.setAttributesForItem(state, action.layerID, action.itemID, action.lineItemID, action.changes).updatedState;

    case XD_LINE_ITEM_REF_ACTIONS.REMOVE_LINE_ITEM_FROM_ITEM:
      return XDLineItemRef.removeFromItem(state, action.layerID, action.itemID, action.lineItemID).updatedState;

    case XD_LINE_ITEM_REF_ACTIONS.ADD_LINE_ITEM_TO_PROJECT:
      return XDLineItemRef.addToProject(state, action.lineItemID, action.quantity).updatedState;

    case XD_LINE_ITEM_REF_ACTIONS.EDIT_LINE_ITEM_IN_PROJECT:
      return XDLineItemRef.setAttributesForProject(state, action.lineItemID, action.changes).updatedState;

    case XD_LINE_ITEM_REF_ACTIONS.REMOVE_LINE_ITEM_FROM_PROJECT:
      return XDLineItemRef.removeFromProject(state, action.lineItemID).updatedState;

    default:
      return state;
  }
}
