import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MdSettings, MdUndo, MdDirectionsRun, MdAttachMoney, MdExitToApp, MdPrint, MdDelete, MdDone, MdCheckBoxOutlineBlank, MdCheckBox } from 'react-icons/md';
import { FaFile, FaMousePointer, FaPlus } from 'react-icons/fa';
import ToolbarButton from './toolbar-button';
import ToolbarSaveButton from './toolbar-save-button';
import ToolbarLoadButton from './toolbar-load-button';
import If from '../../utils/react-if';
import {
  MODE_IDLE,
  MODE_3D_VIEW,
  MODE_3D_FIRST_PERSON,
  MODE_VIEWING_CATALOG,
  MODE_CONFIGURING_PROJECT,
  XD_TOOLBAR
} from '../../constants';
import * as SharedStyle from '../../shared-style';
import { exitProject } from '../../utils/xd-project';
import * as constants from '../../constants';
import { Button, Dialog, XDFeetInchesInput } from '../style/export';

const iconTextStyle = {
  fontSize: '19px',
  textDecoration: 'none',
  fontWeight: 'bold',
  margin: '0px',
  userSelect: 'none'
};

const Icon2D = ( {style} ) => <p style={{...iconTextStyle, ...style}}>2D</p>;
const Icon3D = ( {style} ) => <p style={{...iconTextStyle, ...style}}>3D</p>;

const ASIDE_STYLE = {
  backgroundColor: SharedStyle.PRIMARY_COLOR.main,
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
};

const TOOLBAR_BUTTON_TOP_MARGIN_STYLE = {
  marginTop: '100%'
};

const filterButtonsCb = (toBottom) => (el) => !!el.toBottom === !!toBottom;

const sortButtonsCb = (a, b) => {
  if (a.index === undefined || a.index === null) {
    a.index = Number.MAX_SAFE_INTEGER;
  }

  if (b.index === undefined || b.index === null) {
    b.index = Number.MAX_SAFE_INTEGER;
  }

  return a.index - b.index;
};

const mapButtonsCb = (el, ind) => {
  return (
    <If
      key={ind}
      condition={el.condition}
      style={{ position: 'relative' }}
    >
      {el.dom}
    </If>
  );
};

const printFloorPlan = () => {
  const printWind = window.open('', 'PRINT' , 'width=800,height=600');
  const floorPlanOrig = document.getElementById('floor-plan-view');
  //const floorPlan = floorPlanOrig.cloneNode(true);
  // https://stackoverflow.com/a/6310120/21537170
  function getSerializerWithStyles(elem) {
      // Mapping between tag names and css default values lookup tables. This allows to exclude default values in the result.
      var defaultStylesByTagName = {};
      // Styles inherited from style sheets will not be rendered for elements with these tag names
      var noStyleTags = {"BASE":true,"HEAD":true,"HTML":true,"META":true,"NOFRAME":true,"NOSCRIPT":true,"PARAM":true,"SCRIPT":true,"STYLE":true,"TITLE":true};
      // This list determines which css default values lookup tables are precomputed at load time
      // Lookup tables for other tag names will be automatically built at runtime if needed
      var tagNames = ["A","ABBR","ADDRESS","AREA","ARTICLE","ASIDE","AUDIO","B","BASE","BDI","BDO","BLOCKQUOTE","BODY","BR","BUTTON","CANVAS","CAPTION","CENTER","CITE","CODE","COL","COLGROUP","COMMAND","DATALIST","DD","DEL","DETAILS","DFN","DIV","DL","DT","EM","EMBED","FIELDSET","FIGCAPTION","FIGURE","FONT","FOOTER","FORM","H1","H2","H3","H4","H5","H6","HEAD","HEADER","HGROUP","HR","HTML","I","IFRAME","IMG","INPUT","INS","KBD","KEYGEN","LABEL","LEGEND","LI","LINK","MAP","MARK","MATH","MENU","META","METER","NAV","NOBR","NOSCRIPT","OBJECT","OL","OPTION","OPTGROUP","OUTPUT","P","PARAM","PRE","PROGRESS","Q","RP","RT","RUBY","S","SAMP","SCRIPT","SECTION","SELECT","SMALL","SOURCE","SPAN","STRONG","STYLE","SUB","SUMMARY","SUP","SVG","TABLE","TBODY","TD","TEXTAREA","TFOOT","TH","THEAD","TIME","TITLE","TR","TRACK","U","UL","VAR","VIDEO","WBR"];
      // Precompute the lookup tables.
      for (var i = 0; i < tagNames.length; i++) {
          if(!noStyleTags[tagNames[i]]) {
              defaultStylesByTagName[tagNames[i]] = computeDefaultStyleByTagName(tagNames[i]);
          }
      }
      function computeDefaultStyleByTagName(tagName) {
          var defaultStyle = {};
          var element = document.body.appendChild(document.createElement(tagName));
          var computedStyle = getComputedStyle(element);
          for (var i = 0; i < computedStyle.length; i++) {
              defaultStyle[computedStyle[i]] = computedStyle[computedStyle[i]];
          }
          document.body.removeChild(element);
          return defaultStyle;
      }
      function getDefaultStyleByTagName(tagName) {
          tagName = tagName.toUpperCase();
          if (!defaultStylesByTagName[tagName]) {
              defaultStylesByTagName[tagName] = computeDefaultStyleByTagName(tagName);
          }
          return defaultStylesByTagName[tagName];
      }
      return function() {
          if (elem.nodeType !== Node.ELEMENT_NODE) { throw new TypeError(); }
          var cssTexts = [];
          var elements = elem.querySelectorAll("*");
          for ( var i = 0; i < elements.length; i++ ) {
              var e = elements[i];
              if (!noStyleTags[e.tagName]) {
                  var computedStyle = getComputedStyle(e);
                  var defaultStyle = getDefaultStyleByTagName(e.tagName);
                  cssTexts[i] = e.style.cssText;
                  for (var ii = 0; ii < computedStyle.length; ii++) {
                      var cssPropName = computedStyle[ii];
                      if (computedStyle[cssPropName] !== defaultStyle[cssPropName]) {
                          e.style[cssPropName] = computedStyle[cssPropName];
                      }
                  }
              }
          }
          var result = elem.outerHTML;
          for ( var i = 0; i < elements.length; i++ ) {
              elements[i].style.cssText = cssTexts[i];
          }
          return String(result);
      }
  }
  function unsetSize(node, depth = 0) {
    node.style['width']      = 'unset';
    node.style['height']     = 'unset';
    node.style['max-width']  = 'unset';
    node.style['max-height'] = 'unset';
    node.style['min-width']  = 'unset';
    node.style['min-height'] = 'unset';
  //node.style['overflow']   = 'hidden';
    if (depth > 0) {
      Array.from(node.children).forEach(child => unsetSize(child, depth - 1));
    }
  }
  const serialized = getSerializerWithStyles(floorPlanOrig)();
  const floorPlan = document.createElement('div');
  floorPlan.innerHTML = serialized;
  unsetSize(floorPlan, 1);
  const PAPER_SIZE = { width: 8.5, height: 11 };
  const PAPER_MARGIN_SIZE = { top: 0.5, right: 0.5, bottom: 0.5, left: 0.5 };
  const DOC_SIZE = {
    width: PAPER_SIZE.width - PAPER_MARGIN_SIZE.left - PAPER_MARGIN_SIZE.right,
    height: PAPER_SIZE.height - PAPER_MARGIN_SIZE.top - PAPER_MARGIN_SIZE.bottom,
  };
  const BODY_STYLE = `width:${DOC_SIZE.width}in;height:${DOC_SIZE.height}in`; // "overflow:hidden";
  floorPlan.style['max-width']  = '100%';
  floorPlan.style['max-height'] = '100%';
  floorPlan.style['overflow']   = 'hidden';
  printWind.document.write(`
    <!DOCTYPE html>
    <html>
      <head>
        <title>${document.title}</title>
        <style type="text/css">
          @page {
            size: ${PAPER_SIZE.width}in ${PAPER_SIZE.height}in;
            margin: ${PAPER_MARGIN_SIZE.top}in ${PAPER_MARGIN_SIZE.right}in ${PAPER_MARGIN_SIZE.bottom}in ${PAPER_MARGIN_SIZE.left}in;
          }
        </style>
      </head>
      <body style="${BODY_STYLE}">
        ${ floorPlan.outerHTML }
      </body>
    </html>
  `);
  Array.from(document.styleSheets).forEach(styleSheet => {
    if (styleSheet.href) {
      const newLink = printWind.document.createElement('link');
      newLink.rel = 'stylesheet';
      newLink.href = styleSheet.href;
      printWind.document.head.appendChild(newLink);
    }
  });
  printWind.document.close(); // necessary for IE >= 10
  printWind.focus(); // necessary for IE >= 10*/
  printWind.print();
  //printWind.close();
}

export default class Toolbar extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  // @n8: Disabled to fix JLF-38 https://experience-driven.atlassian.net/browse/JLF-38
  // shouldComponentUpdate(nextProps, nextState) {
  //   return this.props.state.mode !== nextProps.state.mode ||
  //     this.props.height !== nextProps.height ||
  //     this.props.width !== nextProps.width ||
  //     this.props.state.alterate !== nextProps.state.alterate;
  // }

  render() {

    let {
      props: { state, width, height, toolbarButtons, allowProjectFileSupport },
      context: { projectActions, linesActions, viewer3DActions, translator }
    } = this;

    let mode = state.get('mode');
    let alterate = state.get('alterate');
    let alterateColor = alterate ? SharedStyle.MATERIAL_COLORS[500].orange : '';
    let layerID = state.getIn(['scene', 'selectedLayer']);
    let drawingConfirmationEnabled = state.get('drawingConfirmationEnabled');

    let isSelecting = () => {
      const selected = state.getIn(['scene', 'layers', layerID, 'selected']);
      return selected.lines.size > 0 || selected.holes.size > 0 || selected.areas.size > 0 || selected.items.size > 0;
    }

    let sorter = [
      {
        index: 0, condition: true, dom: <ToolbarButton
          active={[MODE_IDLE].includes(mode)}
          style={TOOLBAR_BUTTON_TOP_MARGIN_STYLE}
          tooltip={translator.t('2D View')}
          onClick={event => projectActions.setMode( MODE_IDLE )}>
          {[MODE_3D_FIRST_PERSON, MODE_3D_VIEW].includes(mode) ? <Icon2D style={{color: alterateColor}} /> : <FaMousePointer style={{color: alterateColor}} />}
        </ToolbarButton>
      },
      /*{
        index: 10, condition: true, dom: <ToolbarButton
          active={[MODE_3D_VIEW].includes(mode)}
          tooltip={translator.t('3D View')}
          onClick={event => viewer3DActions.selectTool3DView()}>
          <Icon3D />
        </ToolbarButton>
      },*/
      /*{
        index: 20, condition: true, dom: <ToolbarButton
          active={[MODE_3D_FIRST_PERSON].includes(mode)}
          tooltip={translator.t('3D First Person')}
          onClick={event => viewer3DActions.selectTool3DFirstPerson()}>
          <MdDirectionsRun />
        </ToolbarButton>
      },*/
      {
        index: 30, condition: true,
        dom: <ToolbarButton
          active={[MODE_VIEWING_CATALOG].includes(mode)}
          tooltip={translator.t('Open catalog')}
          onClick={event => projectActions.openCatalog()}>
          <FaPlus />
        </ToolbarButton>
      },
      {
        index: 40, condition: true, dom: <ToolbarButton
          active={false}
          style={TOOLBAR_BUTTON_TOP_MARGIN_STYLE}
          tooltip={translator.t('Undo (CTRL-Z)')}
          onClick={event => projectActions.undo()}>
          <MdUndo />
        </ToolbarButton>
      },
      {
        index: 50,
        condition: mode === constants.MODE_IDLE && isSelecting(),
        dom: <ToolbarButton
          active={false}
          style={TOOLBAR_BUTTON_TOP_MARGIN_STYLE}
          tooltip="Delete Selected (DEL)"
          onClick={event => projectActions.remove()}>
          <MdDelete />
        </ToolbarButton>
      },
      /*
      {
        index: 60, condition: allowProjectFileSupport, dom: <ToolbarButton
          active={false}
          tooltip={translator.t('New project')}
          onClick={event => confirm(translator.t('Would you want to start a new Project?')) ? projectActions.newProject() : null}>
          <FaFile />
        </ToolbarButton>
      },
      */
      /*
      {
        index: 70, condition: allowProjectFileSupport,
        dom: <ToolbarLoadButton state={state} />
      },
      */
      {
        index: 80,
        condition: allowProjectFileSupport,
        toBottom: true,
        dom: <ToolbarSaveButton state={state} />
      },
      {
        index: 90,
        condition: true,
        toBottom: true,
        dom: <ToolbarButton
          active={false}
          tooltip="Print Floor Plan"
          onClick={event => {
            projectActions.setShowAllMeasurements(true);
            new Promise(resolve => setTimeout(resolve, 0))
              .then(() => printFloorPlan())
              .finally(() => projectActions.setShowAllMeasurements(false));
          }}
        >
          <MdPrint />
        </ToolbarButton>
      },
      {
        index: 100,
        condition: true,
        toBottom: true,
        dom: <ToolbarButton
          active={[XD_TOOLBAR.PROJECT_ESTIMATE].includes(mode)}
          tooltip="View project estimate"
          onClick={event => projectActions.openProjectEstimate()}
        >
          <MdAttachMoney />
        </ToolbarButton>
      },
      {
        index: 110,
        condition: true,
        toBottom: true,
        dom: <ToolbarButton
          active={[MODE_CONFIGURING_PROJECT].includes(mode)}
          tooltip={translator.t('Configure project')}
          onClick={event => projectActions.openProjectConfigurator()}>
          <MdSettings />
        </ToolbarButton>
      },
      {
        index: 120,
        condition: true,
        toBottom: true,
        dom: <ToolbarButton
          active={false}
          style={TOOLBAR_BUTTON_TOP_MARGIN_STYLE}
          tooltip="Exit editor"
          onClick={event => exitProject()}
        >
          <MdExitToApp />
        </ToolbarButton>
      },
    ];

    let onStopDrawing = () => {
      if (mode === constants.MODE_DRAWING_LINE) {
        projectActions.remove();
      }
      projectActions.setMode(constants.MODE_IDLE);
    }

    let onToggleDrawingConfirmLength = () => {
      projectActions.setDrawingConfirmationEnabled(!drawingConfirmationEnabled);
    }

    if ([ constants.MODE_WAITING_DRAWING_LINE, constants.MODE_DRAWING_LINE ].includes(mode)) {
      sorter = [
        {
          index: 0,
          condition: true,
          dom: <ToolbarButton
            active={false}
            style={TOOLBAR_BUTTON_TOP_MARGIN_STYLE}
            tooltip="Stop Drawing"
            onClick={onStopDrawing}
          >
            <MdDone />
          </ToolbarButton>
        },
        {
          index: 1,
          condition: true,
          dom: <div>
            <ToolbarButton
              active={false}
              tooltip="Toggle Confirm Length"
              onClick={onToggleDrawingConfirmLength}
            >
              {
                !!drawingConfirmationEnabled ?
                  <MdCheckBox /> :
                  <MdCheckBoxOutlineBlank />
              }
            </ToolbarButton>
            <div style={{fontSize:'10px', color:'white'}}>
              Confirm Length
            </div>
          </div>
        },
      ];
    }

    sorter = sorter.concat(toolbarButtons.map((Component, key) => {
      return Component.prototype ? //if is a react component
        {
          condition: true,
          dom: React.createElement(Component, { mode, state, key })
        } :
        {                           //else is a sortable toolbar button
          index: Component.index,
          condition: Component.condition,
          dom: React.createElement(Component.dom, { mode, state, key })
        };
    }));

    return (
      <aside style={{ ...ASIDE_STYLE, maxWidth: width, maxHeight: height }} className='toolbar'>
        {sorter.filter(filterButtonsCb(false)).sort(sortButtonsCb).map(mapButtonsCb)}
        <div style={{ flex: 1 }} />
        {sorter.filter(filterButtonsCb(true)).sort(sortButtonsCb).map(mapButtonsCb)}
      </aside>
    )
  }
}

Toolbar.propTypes = {
  state: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  allowProjectFileSupport: PropTypes.bool.isRequired,
  toolbarButtons: PropTypes.array
};

Toolbar.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  viewer2DActions: PropTypes.object.isRequired,
  viewer3DActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  holesActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
};
