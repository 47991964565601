import { XD_USER_NOTES } from '../xd-constants';

export function updateLineUserNotes(layerID, lineID, userNotes) {
  return {
    type: XD_USER_NOTES.UPDATE_LINE_USER_NOTES,
    layerID,
    lineID,
    userNotes,
  }
}

export function updateAreaUserNotes(layerID, areaID, userNotes) {
  return {
    type: XD_USER_NOTES.UPDATE_AREA_USER_NOTES,
    layerID,
    areaID,
    userNotes,
  }
}

export function updateHoleUserNotes(layerID, holeID, userNotes) {
  return {
    type: XD_USER_NOTES.UPDATE_HOLE_USER_NOTES,
    layerID,
    holeID,
    userNotes,
  }
}

export function updateItemUserNotes(layerID, itemID, userNotes) {
  return {
    type: XD_USER_NOTES.UPDATE_ITEM_USER_NOTES,
    layerID,
    itemID,
    userNotes,
  }
}
