import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as SharedStyle from '../../shared-style';
import Button from './button';

const BUTTON_LARGE = {
  width: '3em',
  height: '3em',
  fontSize: '1.5em',
  margin: '0 0.2em',
  padding: '0',
};

const BUTTON_MEDIUM = {
  width: '2em',
  height: '2em',
  fontSize: '1em',
  margin: '0 0.2em',
  padding: '0',
};

const BUTTON_SMALL = {
  width: '1.5em',
  height: '1.5em',
  fontSize: '0.8em',
  margin: '0 0.2em',
  padding: '0',
};

export default class XDPagination extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { style: userStyle, buttonStyle: userButtonStyle, page, pageSize, total, onChange } = this.props;
    const numPages = Math.ceil(total / pageSize);
    const BUTTON_STYLE = {
      large: BUTTON_LARGE,
      medium: BUTTON_MEDIUM,
      small: BUTTON_SMALL,
    }[this.props.size];
    return <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      ...userStyle,
    }}>
      <Button
        title="First Page"
        style={{ ...BUTTON_STYLE, ...userButtonStyle}}
        styleHover={{ ...BUTTON_STYLE, ...userButtonStyle}}
        onClick={() => onChange(1)}
        disabled={page === 1}
      >
        &lt;&lt;
      </Button>
      <Button
        title="Previous Page"
        style={{ ...BUTTON_STYLE, ...userButtonStyle}}
        styleHover={{ ...BUTTON_STYLE, ...userButtonStyle}}
        onClick={() => onChange(page - 1)}
        disabled={page === 1}
      >
        &lt;
      </Button>
      <span style={{
        margin: '0 0.5em',
      }}>
        {page} of {numPages}
      </span>
      <Button
        title="Next Page"
        style={{ ...BUTTON_STYLE, ...userButtonStyle}}
        styleHover={{ ...BUTTON_STYLE, ...userButtonStyle}}
        onClick={() => onChange(page + 1)}
        disabled={page >= numPages}
      >
        &gt;
      </Button>
      <Button
        title="Last Page"
        style={{ ...BUTTON_STYLE, ...userButtonStyle}}
        styleHover={{ ...BUTTON_STYLE, ...userButtonStyle}}
        onClick={() => onChange(numPages)}
        disabled={page >= numPages}
      >
        &gt;&gt;
      </Button>
    </div>
  }
}

XDPagination.propTypes = {
  style: PropTypes.object,
  buttonStyle: PropTypes.object,
  size: PropTypes.string,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  total: PropTypes.number,
  onChange: PropTypes.func,
};

XDPagination.defaultProps = {
  style: {},
  buttonStyle: {},
  size: 'medium',
  page: 1,
  pageLimit: 20,
  total: 0,
  onChange: () => {},
};
