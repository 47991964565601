import React, {Component} from 'react';
import PropTypes from 'prop-types';
import convert from 'convert-units';
import {
  ContentTitle,
  ContentContainer,
  FormLabel,
  FormBlock,
  FormNumberInput,
  FormSubmitButton,
  CancelButton
} from '../style/export';
import { calculateProjectEstimateFromScene } from '../../utils/xd-project';

export default class ProjectEstimator extends Component {

  constructor(props, context) {
    super(props, context);
    const estimates = calculateProjectEstimateFromScene(props.state.scene);
    this.state = {
      estimates,
    };
    console.warn('estimates', estimates);
  }

  getUnits() {
    const { state: { scene }, units } = this.props;
    return units || scene.units || 'ft';
  }

  currencyFormat(value) {
    return Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
    }).format(value);
  }

  numberFormat(value) {
    return Intl.NumberFormat('en-CA', {
      maximumFractionDigits: 2,
    }).format(value);
  }

  /**
   * @param {import('../../utils/xd-project').LineItemComputed} lineItem
   */
  renderMaterialLineItem(lineItem) {
    if (lineItem.isLabour) {
      throw new Error('Attempted to render labour line item as material line item');
    }
    const {state: { scene }} = this.props;
    const {estimates} = this.state;
    const sceneLineItem = scene.lineItems.get(lineItem.id);
    const units = this.getUnits();
    const totalLength = lineItem.walls.reduce((acc, wall) => {
      const computedWall = estimates.wallsComputed.find(w => w.id === wall.id);
      acc += convert(computedWall.length).from(computedWall.unit).to(units) * wall.quantity;
      return acc;
    }, 0);
    const totalArea = lineItem.areas.reduce((acc, area) => {
      const computedArea = estimates.areasComputed.find(a => a.id === area.id);
      acc += convert(computedArea.area).from(`${computedArea.unit}2`).to(`${units}2`) * area.quantity;
      return acc;
    }, 0);
    return <div key={lineItem.id}>
      <table style={{width: '100%', borderBottom: '1px solid #ccc', padding: '0.5rem', verticalAlign: 'middle'}}>
        <tbody>
          <tr>
            <td>
              <h3 style={{margin: '0'}}>{sceneLineItem.name}</h3>
            </td>
            <td style={{textAlign: 'right'}}>
              <b>{this.currencyFormat(lineItem.cost)}</b>
            </td>
          </tr>
          <tr>
            <td>
              {
                !!totalLength ?
                  <div title={`Total wall length: ${totalLength} ${units}`}>
                    Total wall length: {this.numberFormat(totalLength)} {units}
                  </div> :
                  null
              }
              {
                !!totalArea ?
                  <div title={`Total area: ${totalArea} ${units}²`}>
                    Total area: {this.numberFormat(totalArea)} {units}²
                  </div> :
                  null
              }
            </td>
            <td style={{textAlign: 'right'}}>
              Qty: {lineItem.quantity}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  }

  renderLabourLineItem(lineItem) {
    if (!lineItem.isLabour) {
      throw new Error('Attempted to render material line item as labour line item');
    }
    const {state: { scene }} = this.props;
    const {estimates} = this.state;
    const sceneLineItem = scene.lineItems.get(lineItem.id);
    return <div key={lineItem.id}>
      <table style={{width: '100%', borderBottom: '1px solid #ccc', padding: '0.5rem', verticalAlign: 'middle'}}>
        <tbody>
          <tr>
            <td>
              <h3 style={{margin: '0'}}>{sceneLineItem.name}</h3>
            </td>
            <td style={{textAlign: 'right'}}>
              <b>{this.currencyFormat(lineItem.cost)}</b>
            </td>
          </tr>
          <tr>
            <td>
              Hourly rate: {this.currencyFormat(sceneLineItem.costPerUnit)}
            </td>
            <td style={{textAlign: 'right'}}>
              Hours: {lineItem.quantity}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  }

  render() {
    let {state: appState, width, height} = this.props;
    let {estimates} = this.state;
    let {projectActions, translator} = this.context;

    const materialItems = estimates.lineItems.filter(li => !li.isLabour);
    const labourItems = estimates.lineItems.filter(li => li.isLabour);

    return (
      <ContentContainer width={width} height={height}>
        <ContentTitle>Project Estimates</ContentTitle>
        <h2>Material</h2>
        <div style={{ overflowY: 'auto', padding: '0 2rem', maxHeight: '80%' }}>
          { materialItems.map(li => this.renderMaterialLineItem(li)) }
          { materialItems.length === 0 && <div>No material items</div> }
        </div>
        <h2>Labour</h2>
        <div style={{ overflowY: 'auto', padding: '0 2rem', maxHeight: '80%' }}>
          { labourItems.map(li => this.renderLabourLineItem(li)) }
          { labourItems.length === 0 && <div>No labour items</div> }
        </div>
        <table style={{width: '100%', marginTop: '1rem'}}>
          <tbody>
            <tr>
              <td>
                <h2>
                  Sub-Total: { this.currencyFormat(estimates.subTotal) }
                </h2>
              </td>
              <td>
                <div style={{float: 'right'}}>
                  <CancelButton size='large' onClick={e => projectActions.rollback()}>
                    Leave
                  </CancelButton>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ContentContainer>
    )
  }
}

ProjectEstimator.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  state: PropTypes.object.isRequired,
  units: PropTypes.string,
};

ProjectEstimator.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
};
