import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Map, List, fromJS} from 'immutable';
import * as SharedStyle from '../../../../shared-style';
import {TiPlus, TiDelete, TiUpload} from 'react-icons/ti';
import {FaTrash, FaEye, FaLink, FaUnlink} from 'react-icons/fa';
import {FormTextInput, FormNumberInput, FormCheckbox, Button} from '../../../style/export';
import XDLineItemCatalog from './xd-line-item-catalog';



const styleHoverColor = {color: SharedStyle.SECONDARY_COLOR.main};
const styleAddLabel = {fontSize: '10px', marginLeft: '5px'};
const labelStyle = {fontSize: '1.3em', cursor: 'pointer', textAlign: 'center'};
const labelHoverStyle = {...labelStyle, ...styleHoverColor};
const rowMargin = {marginTop: '1em', marginBottom: '1em'};

export default class XDLineItemEditor extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = this.initState(this.props.state);
  }

  initState(state) {
    let { lineItemID, state: appState } = this.props;
    let lineItem = appState.getIn(['scene', 'lineItems', lineItemID]);
    let formData = new Map({
      name: lineItem.get('name'),
      baseCost: lineItem.get('baseCost'),
      costPerUnit: lineItem.get('costPerUnit'),
      unit: lineItem.get('unit'),
    });
    return {
      formData: formData,
      showCatalog: false,
    };
  }

  reset() {
    this.setState(this.initState(this.props.state));
  }

  updateField(field, value) {
    let { formData } = this.state;
    formData = formData.set(field, value);
    this.setState({ formData });
  }

  showCatalog() {
    this.setState({ showCatalog: true });
  }

  hideCatalog() {
    this.setState({ showCatalog: false });
  }

  importLineItemFromCatalog(lineItem, lineItemType) {
    let { onSave } = this.props;
    let { formData } = this.state;
    if (!lineItem) {
      throw new Error('No line item provided to import');
    }
    if (lineItemType !== 'material' && lineItemType !== 'labour') {
      throw new Error(`Invalid line item type: ${lineItemType}`);
    }
    formData = formData.merge({
      name: lineItem.get('name'),
      baseCost: lineItem.get('cost_base'),
      costPerUnit: lineItem.get('cost_per_unit'),
      unit: lineItemType === 'material' ? lineItem.get('cost_unit') : 'hours',
    });
    this.setState({ formData });
    this.hideCatalog();
    onSave(formData, false);
  }

  render() {
    let {
      state: {formData, showCatalog},
      props: {state: appState, lineItemID, onSave, onCancel},
    } = this;

    const unit = formData.get('unit') || appState.getIn(['scene', 'unit']);

    return ( showCatalog ?
      <div>
        <XDLineItemCatalog
          state={appState}
          onAdd={ (lineItem, type) => this.importLineItemFromCatalog(lineItem, type) }
          onCancel={ e => this.hideCatalog() }
        />
      </div> :
      <div>
        <div style={{...rowMargin, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          <div>
            { this.props.isNew ? 'Create new line item' : 'Edit line item' }
          </div>
          <div>
            <Button
              title="Import item from catalog"
              onClick={ e => this.showCatalog() }
            >
              <TiUpload />
              Catalog
            </Button>
          </div>
        </div>
        <table style={{ width: '100%'}}>
          <tbody>
            <tr>
              <td>Name</td>
              <td>
                <FormTextInput
                  value={formData.get('name')}
                  onChange={ e => this.updateField('name', e.target.value) }
                  state={appState}
                />
              </td>
            </tr>
            <tr>
              <td>{/* Is Labour? */}</td>
              <td>
                <FormCheckbox
                  checked={formData.get('unit') === 'hours'}
                  onChange={ e => this.updateField('unit', !!e.target.checked ? 'hours' : unit) }
                  label="Is Labour?"
                />
              </td>
            </tr>
            {
              formData.get('unit') !== 'hours' &&
              <tr>
                <td>Base Cost</td>
                <td>
                  <FormNumberInput
                    value={formData.get('baseCost')}
                    onChange={ e => this.updateField('baseCost', e.target.value) }
                    state={appState}
                    immediate={true}
                  />
                </td>
              </tr>
            }
            <tr>
              {/* TODO: Should this exist on items/holes? */}
              <td>
                {
                  formData.get('unit') === 'hours' ?
                    'Cost per hour' :
                    `Cost per ${unit}`
                }
              </td>
              <td>
                <FormNumberInput
                  value={formData.get('costPerUnit')}
                  onChange={ e => this.updateField('costPerUnit', e.target.value) }
                  state={appState}
                  immediate={true}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div style={rowMargin}>
          <Button onClick={ e => onSave(formData, false) }>
            <TiPlus />
            <b style={styleAddLabel}>
              { this.props.isNew ? 'Save Item' : 'Save Item (update all existing)' }
            </b>
          </Button>
        </div>
        {
          !this.props.isNew &&
          <div style={rowMargin}>
            <Button onClick={ e => onSave(formData, true) }>
              <TiPlus />
              <b style={styleAddLabel}>Save Item (as new copy)</b>
            </Button>
          </div>
        }
        <div style={rowMargin}>
          <Button onClick={ e => onCancel() }>
            <TiDelete />
            <b style={styleAddLabel}>Cancel</b>
          </Button>
        </div>
      </div>
    )
  }
}

XDLineItemEditor.propTypes = {
  state: PropTypes.object.isRequired,
  lineItemID: PropTypes.string.isRequired,
  isNew: PropTypes.bool,
  onSave: PropTypes.func, // onSave(formData, saveAsCopy = false)
  onCancel: PropTypes.func, // onCancel()
};

XDLineItemEditor.contextTypes = {
  catalog: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
};
