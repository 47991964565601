const DEBUG_MODE = true;

/**
* Post a message to the top window.
* @param {any} msg
*/
const sendMessage = (msg) => {
  if (window.top) {
    DEBUG_MODE && console.log("Sending message to top window", msg);
    window.top.postMessage(msg, '*');
  } else {
    console.error("No top window to post message to");
  }
}

export {
  sendMessage,
}
