// XDLineItem
export const NEW_LINE_ITEM = 'NEW_LINE_ITEM';
export const EDIT_LINE_ITEM = 'EDIT_LINE_ITEM';
export const REMOVE_LINE_ITEM = 'REMOVE_LINE_ITEM';

// XDLineItemRef
export const ADD_LINE_ITEM_TO_AREA = 'ADD_LINE_ITEM_TO_AREA';
export const EDIT_LINE_ITEM_IN_AREA = 'EDIT_LINE_ITEM_IN_AREA';
export const REMOVE_LINE_ITEM_FROM_AREA = 'REMOVE_LINE_ITEM_FROM_AREA';
export const ADD_LINE_ITEM_TO_LINE = 'ADD_LINE_ITEM_TO_LINE';
export const EDIT_LINE_ITEM_IN_LINE = 'EDIT_LINE_ITEM_IN_LINE';
export const REMOVE_LINE_ITEM_FROM_LINE = 'REMOVE_LINE_ITEM_FROM_LINE';
export const ADD_LINE_ITEM_TO_HOLE = 'ADD_LINE_ITEM_TO_HOLE';
export const EDIT_LINE_ITEM_IN_HOLE = 'EDIT_LINE_ITEM_IN_HOLE';
export const REMOVE_LINE_ITEM_FROM_HOLE = 'REMOVE_LINE_ITEM_FROM_HOLE';
export const ADD_LINE_ITEM_TO_ITEM = 'ADD_LINE_ITEM_TO_ITEM';
export const EDIT_LINE_ITEM_IN_ITEM = 'EDIT_LINE_ITEM_IN_ITEM';
export const REMOVE_LINE_ITEM_FROM_ITEM = 'REMOVE_LINE_ITEM_FROM_ITEM';
export const ADD_LINE_ITEM_TO_PROJECT = 'ADD_LINE_ITEM_TO_PROJECT';
export const EDIT_LINE_ITEM_IN_PROJECT = 'EDIT_LINE_ITEM_IN_PROJECT';
export const REMOVE_LINE_ITEM_FROM_PROJECT = 'REMOVE_LINE_ITEM_FROM_PROJECT';

// User notes
export const UPDATE_LINE_USER_NOTES = 'UPDATE_LINE_USER_NOTES';
export const UPDATE_AREA_USER_NOTES = 'UPDATE_AREA_USER_NOTES';
export const UPDATE_HOLE_USER_NOTES = 'UPDATE_HOLE_USER_NOTES';
export const UPDATE_ITEM_USER_NOTES = 'UPDATE_ITEM_USER_NOTES';

// Toolbar
export const MODE_PROJECT_ESTIMATE = 'MODE_PROJECT_ESTIMATE';


export const XD_LINE_ITEM_ACTIONS = {
  NEW_LINE_ITEM,
  EDIT_LINE_ITEM,
  REMOVE_LINE_ITEM,
};

export const XD_LINE_ITEM_REF_ACTIONS = {
  ADD_LINE_ITEM_TO_AREA,
  EDIT_LINE_ITEM_IN_AREA,
  REMOVE_LINE_ITEM_FROM_AREA,
  ADD_LINE_ITEM_TO_LINE,
  EDIT_LINE_ITEM_IN_LINE,
  REMOVE_LINE_ITEM_FROM_LINE,
  ADD_LINE_ITEM_TO_HOLE,
  EDIT_LINE_ITEM_IN_HOLE,
  REMOVE_LINE_ITEM_FROM_HOLE,
  ADD_LINE_ITEM_TO_ITEM,
  EDIT_LINE_ITEM_IN_ITEM,
  REMOVE_LINE_ITEM_FROM_ITEM,
  ADD_LINE_ITEM_TO_PROJECT,
  EDIT_LINE_ITEM_IN_PROJECT,
  REMOVE_LINE_ITEM_FROM_PROJECT,
};

export const XD_USER_NOTES = {
  UPDATE_LINE_USER_NOTES,
  UPDATE_AREA_USER_NOTES,
  UPDATE_HOLE_USER_NOTES,
  UPDATE_ITEM_USER_NOTES,
};

export const XD_TOOLBAR = {
  PROJECT_ESTIMATE: MODE_PROJECT_ESTIMATE,
};
