import { XDLineItem } from '../class/export';
import {
  XD_LINE_ITEM_ACTIONS
} from '../xd-constants';

export default function (state, action) {
  switch (action.type) {

    case XD_LINE_ITEM_ACTIONS.NEW_LINE_ITEM:
      return XDLineItem.create(state, action.baseCost, action.costPerUnit, action.unit, action.name, action.overrideID).updatedState;

    case XD_LINE_ITEM_ACTIONS.EDIT_LINE_ITEM:
      return XDLineItem.setAttributes(state, action.lineItemId, action.changes).updatedState;

    case XD_LINE_ITEM_ACTIONS.REMOVE_LINE_ITEM:
      return XDLineItem.remove(state, action.lineItemId).updatedState;

    default:
      return state;
  }
}
