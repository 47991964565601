class XDUserNotes {

  static updateNotes( state, layerID, objectMapName, objectID, userNotes ) {
    state = state.setIn(['scene', 'layers', layerID, objectMapName, objectID, 'userNotes'], userNotes);
    return { updatedState: state };
  }

  static updateLineNotes( state, layerID, lineID, userNotes ) {
    return XDUserNotes.updateNotes( state, layerID, 'lines', lineID, userNotes );
  }

  static updateAreaNotes( state, layerID, areaID, userNotes ) {
    return XDUserNotes.updateNotes( state, layerID, 'areas', areaID, userNotes );
  }

  static updateHoleNotes( state, layerID, holeID, userNotes ) {
    return XDUserNotes.updateNotes( state, layerID, 'holes', holeID, userNotes );
  }

  static updateItemNotes( state, layerID, itemID, userNotes ) {
    return XDUserNotes.updateNotes( state, layerID, 'items', itemID, userNotes );
  }

}

export { XDUserNotes as default };
