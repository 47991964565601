import React from 'react';
import * as SharedStyle from '../../shared-style';
import * as Geometry from '../../utils/geometry';
import Translator from '../../translator/translator';

const epsilon = 20;
const STYLE_TEXT = { textAnchor: 'middle' };
const STYLE_LINE = { stroke: SharedStyle.LINE_MESH_COLOR.selected };
const STYLE_RECT = { strokeWidth: 0, fill: SharedStyle.LINE_MESH_COLOR.unselected };
const STYLE_RECT_SELECTED = { ...STYLE_RECT, fill: SharedStyle.LINE_MESH_COLOR.selected };

let translator = new Translator();

export default function SeparatorFactory(name, info, textures) {

  let separatorElement = {
    name,
    prototype: 'lines',
    info,
    properties: {
    },

    render2D: function (element, layer, scene) {
      let { x: x1, y: y1 } = layer.vertices.get(element.vertices.get(0));
      let { x: x2, y: y2 } = layer.vertices.get(element.vertices.get(1));

      let length = Geometry.pointsDistance(x1, y1, x2, y2);

      return <g>
        <rect
          x="0"
          y={-1}
          width={length}
          height={2}
          style={element.selected ? STYLE_RECT_SELECTED : STYLE_RECT}
        />
        {/* Transparent padding rect for easier selection */}
        <rect
          x="0"
          y={-5}
          width={length}
          height={10}
          style={{...STYLE_RECT, fill: 'transparent'}}
        />
      </g>
    },

    render3D: function (element, layer, scene) {
      return null;
    },

    updateRender3D: (element, layer, scene, mesh, oldElement, differences, selfDestroy, selfBuild) => {
      return null;
    }

  };

  return separatorElement;
}
