import { Area, Line, Layer } from "../models";
import { pointsDistance, samePoints } from './geometry';
import convert from 'convert-units';
import areapolygon from 'area-polygon';

/**
 * @param {Area} area The area
 * @param {Layer} layer The layer relevant to this area
 * @param {convert.Unit} unit Type of resulting unit
 * @returns {number} Area size in unit^2
 */
function calculateAreaSize(area, layer, unit) {
  // Nabbed from react-planner/src/components/viewer2d/area.jsx
  let polygon = area.vertices.toArray().map(vertexID => {
    let {x, y} = layer.vertices.get(vertexID);
    return [x, y];
  });
  let areaSize = areapolygon(polygon, false);
  //subtract holes area
  area.holes.forEach(areaID => {
    let hole = layer.areas.get(areaID);
    let holePolygon = hole.vertices.toArray().map(vertexID => {
      let {x, y} = layer.vertices.get(vertexID);
      return [x, y];
    });
    areaSize -= areapolygon(holePolygon, false);
  });
  // cm2 - TODO: I believe this is relevant to scene units, but is hardcoded in original
  areaSize = convert(areaSize).from('cm2').to(`${unit}2`);
  return areaSize;
}

/**
 * @param {Line} line The line
 * @param {Layer} layer The layer relevant to this line
 * @param {convert.Unit} unit Type of resulting unit
 * @returns {number} Line length in given units
 */
function calculateLineLength(line, layer, unit) {
  // From react-planner/src/components/viewer2d/line.jsx
  let vertex0 = layer.vertices.get(line.vertices.get(0));
  let vertex1 = layer.vertices.get(line.vertices.get(1));
  if (vertex0.id === vertex1.id || samePoints(vertex0, vertex1))
    return 0; //avoid 0-length lines
  let {x: x1, y: y1} = vertex0;
  let {x: x2, y: y2} = vertex1;
  if (x1 > x2) {
    ({x: x1, y: y1} = vertex1);
    ({x: x2, y: y2} = vertex0);
  }
  let length = pointsDistance(x1, y1, x2, y2);
  // cm - TODO: I believe this is relevant to scene units, but is hardcoded in original
  length = convert(length).from('cm').to(unit);
  return length;
}

function decimalToFtInches(n) {
  let feet = Math.floor(n);
  let inches = (n - feet) * 12;
  return {feet, inches};
}

function decimalToFtInchesString(n, maxDecimalPlaces) {
  let {feet, inches} = decimalToFtInches(n);
  if (maxDecimalPlaces != null) {
    const fmt = Intl.NumberFormat('en-CA', {maximumFractionDigits: maxDecimalPlaces});
    return `${feet}' ${fmt.format(inches)}"`;
  } else {
    return `${feet}' ${inches}"`;
  }
}

export {
  calculateAreaSize,
  calculateLineLength,
  decimalToFtInches,
  decimalToFtInchesString,
};
