import React from 'react';
import * as SharedStyle from '../../shared-style';

const BASE_STYLE = {
  display: "block",
  width: "100%",
  padding: "0px 4px",
  color: SharedStyle.COLORS.white,
  height: "30px",
  display: "flex",
  alignItems: "center",
};

export default function FormSelect({children, label, labelProps, labelStyle, inputProps, inputStyle, ...rest}) {
  return <label style={{...BASE_STYLE, ...labelStyle}} {...labelProps}>
    <input type="checkbox" style={{...inputStyle}} {...inputProps} {...rest} />
    {label}
    {children}
  </label>;
}
