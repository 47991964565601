import {Record} from 'immutable';

export class XDLineItem extends Record({
  id: '',
  name: "",
  baseCost: 0,
  costPerUnit: 0,
  unit: 'cm',
}, 'XDLineItem') {
  constructor(json = {}) {
    super({
      ...json,
    });
  }
}

export class XDLineItemRef extends Record({
  id: '',
  quantity: 0,
  bSideA: true,
  bSideB: true,
}, 'XDLineItemRef') {
  constructor(json = {}) {
    super({
      ...json,
    });
  }
}
