import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Map, List, fromJS} from 'immutable';
import * as SharedStyle from '../../../shared-style';
import {TiPlus, TiDelete, TiPencil} from 'react-icons/ti';
import {FaTrash, FaEye, FaLink, FaUnlink} from 'react-icons/fa';
import {FormTextInputMultiline} from '../../style/export';

export default class XDUserNotesEditor extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = this.initState(this.props.element, this.props.layer, this.props.state);
  }

  componentWillReceiveProps({ element, layer, state }) {
    let scene = this.props.state.get('scene');
    let selectedLayer = scene.getIn(['layers', scene.get('selectedLayer')]);
    if (selectedLayer.hashCode() !== layer.hashCode())
      this.setState(this.initState(this.props.element, this.props.layer, this.props.state));
  }

  initState(element, layer, state) {
    element = typeof element.misc === 'object' ? element.set('misc', new Map(element.misc)) : element;
    const type = element.prototype; // 'items', 'lines', 'holes', 'areas'
    return {
    };
  }

  reset() {
    this.setState(this.initState(this.props.element, this.props.layer, this.props.state));
  }

  onUpdateNotes(value) {
    let {
      context: {userNotesActions},
      props: {element, layer},
    } = this;
    switch (element.prototype) {
      case 'items':
        userNotesActions.updateItemUserNotes(layer.id, element.id, value);
        break;
      case 'lines':
        userNotesActions.updateLineUserNotes(layer.id, element.id, value);
        break;
      case 'holes':
        userNotesActions.updateHoleUserNotes(layer.id, element.id, value);
        break;
      case 'areas':
        userNotesActions.updateAreaUserNotes(layer.id, element.id, value);
        break;
      default:
        console.warn('Attempted to update user notes for unknown element type:', element.prototype);
        break;
    }
  }

  render() {
    const {
      props: { element },
    } = this;
    const userNotes = element.get('userNotes');
    return <div>
      <FormTextInputMultiline
        value={userNotes}
        onChange={e => this.onUpdateNotes(e.target.value)}
        style={{ height:'5rem' }}
      />
    </div>
  }

}

XDUserNotesEditor.propTypes = {
  state: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  layer: PropTypes.object.isRequired
};

XDUserNotesEditor.contextTypes = {
  userNotesActions: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
};
