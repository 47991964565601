import React, { useState, createRef, useEffect } from "react";
import PropTypes from 'prop-types';
import * as SharedStyle from '../../shared-style';

const STYLE_DIALOG_CONTAINER = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,
  overflow: 'hidden',
};

const STYLE_DIALOG_MODAL = {
  backgroundColor: SharedStyle.COLORS.white,
  padding: '20px',
  borderRadius: '5px',
  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
};

export default function Dialog({ open, onChange, onShow, onHide, persistent, style, children, ...rest }, ctx) {

  const refContainer = createRef();
  const [opened, setOpened] = useState(open);

  useEffect(() => {
    if (open === opened) return;
    setOpened(open);
    if (open) {
      onShow && onShow();
    } else {
      onHide && onHide();
    }
  }, [open]);

  const setVisibility = (visible) => {
    if (visible === opened) return;
    setOpened(visible);
    onChange && onChange(visible);
    if (visible) {
      onShow && onShow();
    } else {
      onHide && onHide();
    }
  };

  const onClickBackdrop = (e) => {
    if (persistent) return;
    if (refContainer.current === e.target) {
      setVisibility(false);
      e.stopPropagation();
    }
  };

  if (!opened) return null;

  const styles = Object.assign({}, STYLE_DIALOG_MODAL, style);

  return (
    <div
      ref={refContainer}
      onClick={onClickBackdrop}
      style={STYLE_DIALOG_CONTAINER}
    >
      <div style={styles} {...rest}>
        {children}
      </div>
    </div>
  );
}

Dialog.defaultProps = {
  style: {},
  persistent: false,
  open: false,
  onChange: () => {},
};

Dialog.propTypes = {
  style: PropTypes.object,
  persistent: PropTypes.bool,
  open: PropTypes.bool,
  onChange: PropTypes.func,
  onShow: PropTypes.func,
  onHide: PropTypes.func,
};
